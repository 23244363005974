import {IComponentOptions, IController, IPromise, IQService} from 'angular';
import {UserContextService} from "../user/user-context-service";
import {ProductFrameShapeResource} from "../api/legacy/api/ProductFrameShapeResource";
import * as template from "./product-frame-shapes.component.html";
import {mainModule} from "../app";
import {FrameShape, UserRole} from "vtom-api-typescript-client";
import {FrameShapeUtil} from "./product-enum-utils";


class Controller implements IController {
    // Input
    product;

    // Output
    registerSaveListener: ({listener: ProductSaveListener}) => void;

    initialFrameShapes: string[];
    selectedFrameShapes: string[];
    frameShapes: string[];
    disabled = false;

    readonly toFrameShapeLabelCode = FrameShapeUtil.toLabelCode;

    constructor(
        private readonly $q: IQService,
        private readonly UserContextService: UserContextService,
        private readonly ProductFrameShapeResource: ProductFrameShapeResource
    ) {}


    save(): void {
        if (this.initialFrameShapes.sort().toString() == this.selectedFrameShapes.sort().toString()) {
            // Nothing new to save
            return;
        }
        this.ProductFrameShapeResource.saveForProduct({
            frameShapes: this.selectedFrameShapes.map(shape => ({
                product: this.product,
                frameShape: shape,
            })),
            product: this.product
        });
    };

    isReady(): boolean {
        return this.selectedFrameShapes != null;
    }

    $onInit() {
        this.frameShapes = Object.values(FrameShape);
        if (this.registerSaveListener) {
            this.registerSaveListener({listener: () => this.save()});
        }
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.product?.id) {
            return;
        }
        if (onChangesObj.product.previousValue
            && onChangesObj.product.previousValue.id == this.product.id) {
            return;
        }
        this.ProductFrameShapeResource.findByProduct({product: this.product}).$promise.then(frameShapeEntities => {
            this.selectedFrameShapes = frameShapeEntities.map(entity => entity.frameShape);
            this.initialFrameShapes = Object.assign([], this.selectedFrameShapes);
        });
        this.UserContextService.getContext().then(userContext => {
            this.disabled = !userContext.hasRole(UserRole.ADMIN) && !userContext.hasRole(UserRole.SHOOTER);
        });
    }

}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        product: '<',
        registerSaveListener: '&',
    }
};

mainModule.component('productFrameShapes', component);
