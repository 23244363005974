import * as angular from "angular";
import * as loggerFactory from "loglevel";
import {mainModule} from "../../app";

function HttpErrorHandler($rootScope, $q, DialogService, $translate, InterpolableMessageService, HttpConfig) {

    const log = loggerFactory.getLogger('HttpErrorHandler');

    /**
     * Displays an error modal containing main user message and secondary messages if appropriate
     * @param errorResource The error resource probably provided by the server
     */
    const displayErrorModal = function (errorResource) {
        if (!errorResource) {
            return;
        }

        const errorMessage = errorResource.detail;
        if (errorMessage) {
            DialogService.error(errorMessage);
            return;
        }

        $translate('errorpage.erreurcontacteradmin').then(msg => DialogService.error(msg));
    };

    $rootScope.$on(HttpConfig.HTTP_ERROR_EVENT, function (evt, rejection) {
        if (rejection.config && rejection.config.custom.displayModalOnError === true) {
            const failure = {
                url: rejection.config.url,
                method: rejection.config.method,
                status: rejection.status
            };
            log.error('Http request failed with ' + JSON.stringify(failure));
            displayErrorModal(rejection.data);
        }
    });

}

HttpErrorHandler.$inject = ['$rootScope', '$q', 'DialogService', '$translate', 'InterpolableMessageService', 'HttpConfig'];

export default HttpErrorHandler;
mainModule.service('HttpErrorHandler', HttpErrorHandler);
