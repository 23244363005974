import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ScenarioAccessesBulkConfigurationCtrl', function ($scope, $modalInstance, $translate, toastr,
                                                                      AccessesKeyChainResource, ScenarioAccessResource, scenarios) {
        $scope.titleParam = {productCount: scenarios.length};
        $scope.actions = {
            keychainsToAdd: [],
            keychainsToRemove: [],
        };
        $scope.keychains = AccessesKeyChainResource.query();

        $scope.save = function () {
            ScenarioAccessResource
                .configureAccessesKeyChains({
                    keyChainsToAdd: $scope.actions.keychainsToAdd,
                    keyChainsToRemove: $scope.actions.keychainsToRemove,
                    scenarios: scenarios
                })
                .$promise
                .then(function () {
                    $translate('general.save.success')
                        .then(function(message){
                            toastr.success(message);
                        });
                    $modalInstance.close();
                });
        };

        $scope.canSave = () => $scope.actions.keychainsToAdd.length || $scope.actions.keychainsToRemove.length;

        $scope.cancel = function () {
            $modalInstance.close();
        };
    });
