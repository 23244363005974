import * as angular from 'angular';
import {SupplierResource} from '../api/legacy/api/SupplierResource';

angular.module('virtual-try-on-manager')
    .controller('SupplierListCtrl', function ($scope, SupplierResource: SupplierResource) {
        $scope.sortInfo = {fields: ['name'], columns: [], directions: ['asc']};
        $scope.columns = [
            {field: 'name', labelCode: 'name', linkBaseHref: '#/admin/supplier'},
            {field: 'ediCode', labelCode: 'edi.code'}
        ];
        $scope.findPage = function () {
            return SupplierResource.findPage.apply(SupplierResource, arguments);
        };
    });
