import * as angular from 'angular';
import {IComponentOptions, IController} from 'angular';
import * as template from "./scenario-keychain-edit.component.html";
import {mainModule} from "../../app";
import {ScenarioAccessResource} from "../../api/legacy/api/ScenarioAccessResource";
import {AccessesKeyChainResource} from "../../api/legacy/api/AccessesKeyChainResource";


class Controller implements IController {
    id: number;

    allKeychains: AccessKeychain[];
    originalKeychains: AccessKeychain[];
    selectedKeychains: AccessKeychain[];

    constructor(
        private readonly AccessesKeyChainResource: AccessesKeyChainResource,
        private readonly ScenarioAccessResource: ScenarioAccessResource,
    ) {
    }

    $onInit() {
        this.AccessesKeyChainResource.query({})
            .$promise
            .then(keychains => this.allKeychains = keychains);
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.id || onChangesObj.id.previousValue == onChangesObj.id.currentValue) {
            return;
        }
        this.ScenarioAccessResource
            .findKeychainsForScenario({scenario: {id: this.id}})
            .$promise
            .then(keychains => {
                this.selectedKeychains = keychains;
                this.originalKeychains = [...keychains]
            });
    }

    save() {
        const toAdd = this.selectedKeychains
            .filter(selectedKeychain =>
                !this.originalKeychains.some(originalKeychain => originalKeychain.id == selectedKeychain.id));
        const toDelete = this.originalKeychains
            .filter(originalKeychain =>
                !this.selectedKeychains.some(selectedKeychain => originalKeychain.id == selectedKeychain.id));
        if (!toAdd.length && !toDelete.length) {
            // Nothing changed
            return;
        }
        this.ScenarioAccessResource
            .configureAccessesKeyChains({keyChainsToAdd: toAdd, keyChainsToRemove: toDelete, scenarios: [{id: this.id}]})
            .$promise
            .then(() => this.originalKeychains = [...this.selectedKeychains]);
    }
}

interface AccessKeychain {
    id: number;
    name: string;
}

const component: IComponentOptions = {
    bindings: {
        id: '<'
    },
    controller: Controller,
    template: template.default
};

mainModule.component('scenarioKeychainEdit', component);
