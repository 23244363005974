import {IComponentOptions, IController} from 'angular';
import * as template from './job-scenario-list.html';
import {ScenarioResource} from "../api/legacy/api/ScenarioResource";
import {ProductResource} from "../api/legacy/api/ProductResource";
import {mainModule} from "../app";
import {ScenarioFilter} from "../product/scenario/scenario-filter";
import {CosGridSort} from "../layout/cos-grid-sort";
import {UserContext} from "../user/user-context-service";

class Controller implements IController {

    private jobId: number;

    filter: ScenarioFilter;
    columns = [
        {field: 'type', labelCode: 'shooting.mode', cellFilter: 'enumToString'},
        {field: 'status', labelCode: 'status'},
        {field: 'product.model', labelCode: 'model'},
        {field: 'product.gtin', labelCode: 'gtin.code'},
    ];
    sortInfo: CosGridSort = {fields: ['type', 'product.model'], directions: ['asc', 'asc']};
    scenarioTypes;
    newScenarioTypes = [];

    constructor(
        private readonly $routeParams,
        private readonly ScenarioResource: ScenarioResource,
        private readonly ProductResource: ProductResource,
        private readonly DialogService,
        private readonly $translate,
        private readonly toastr,
        private readonly UserContextService) {
    }

    findPage = (params) => {
        return this.ScenarioResource.findPage(params);
    };

    async $onInit() {
        this.jobId = this.$routeParams.id;
        this.filter = {
            jobIds: [this.jobId],
            entityGraphName: 'scenario.with.customer.and.product'
        };
        const userContext: UserContext = await this.UserContextService.getContext();
        if (userContext.hasRole('ADMIN')) {
            this.scenarioTypes = this.ProductResource.getAllShootingModes({});
        }
    }

    async addScenarios() {
        if (!this.newScenarioTypes.length) {
            this.DialogService.alert(await this.$translate('shooting.mode.mandatory'));
            return;
        }
        await this.ScenarioResource.createForJobIfNotExist({jobId: this.jobId, types: this.newScenarioTypes});
        this.toastr.success(await this.$translate('job.scenarios.add.success'));
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('jobScenarioList', component);
