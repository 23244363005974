import * as angular from 'angular';
import {SupplierResource} from '../api/legacy/api/SupplierResource';

angular.module('virtual-try-on-manager')
    .controller('SupplierCtrl', function ($scope, $routeParams, $location, $window,
                                          SupplierResource: SupplierResource, NavbarService) {
        $scope.isNew = $routeParams.id === 'new';
        $scope.supplierId = $routeParams.id;

        if ($scope.isNew) {
            $scope.supplier = SupplierResource._default();
        } else {
            $scope.supplier = SupplierResource.get(parseInt($routeParams.id));
        }

        NavbarService.setAppTitleFromObjectName('supplier', $scope, 'supplier.name');

        $scope.saveSupplier = function () {
            SupplierResource.save($scope.supplier)
                .$promise
                .then(function (supplier) {
                    if ($scope.isNew) {
                        $location.path('/admin/supplier/' + supplier.id);
                    } else {
                        $scope.supplier = supplier;
                    }
                });
        };
    });
