import {IController, IQService, IScope} from 'angular';
import * as template from "./customer-stats-products.component.html";
import {mainModule} from "../app";
import {CustomerResource} from "../api/legacy/api/CustomerResource";
import {ProductStatusStatResource} from "../api/legacy/api/ProductStatusStatResource";
import {JobCompletionStatResource} from "../api/legacy/api/JobCompletionStatResource";
import {KeychainSharingStatResource} from "../api/legacy/api/KeychainSharingStatResource";
import {UserValidationStatResource} from "../api/legacy/api/UserValidationStatResource";
import {UserContextService} from "../user/user-context-service";
import {CustomerStatFilter} from "./customer-stats-top-filter.component";
import {CustomerStatsBlockConfiguration} from "./customer-stats-block.component";


class Controller implements IController {
    minDate: Date;
    maxDate: Date;

    topFilter: CustomerStatFilter;

    isInternalUser: boolean = false;

    productGlobalStats: CustomerStatsBlockConfiguration;
    productBrandStats: CustomerStatsBlockConfiguration;
    productSupplierStats: CustomerStatsBlockConfiguration;
    jobCompletionStat: CustomerStatsBlockConfiguration;
    keychainSharingStat: CustomerStatsBlockConfiguration;
    userValidationStat: CustomerStatsBlockConfiguration;

    constructor(
        private readonly $scope: IScope,
        private readonly $translate,
        private readonly UserContextService: UserContextService,
        private readonly NavbarService,
        private readonly ProductStatusStatResource: ProductStatusStatResource,
        private readonly JobCompletionStatResource: JobCompletionStatResource,
        private readonly KeychainSharingStatResource: KeychainSharingStatResource,
        private readonly UserValidationStatResource: UserValidationStatResource) {
    }

    onTopFilterChange(filter: CustomerStatFilter) {
        this.topFilter = filter;
    }

    $onInit(): void {
        this.NavbarService.setAppTitle(this.$scope, this.$translate('statistics'));

        this.UserContextService.getContext().then(context => {
            this.isInternalUser = context.user.customer == null;

            this.ProductStatusStatResource.findValidRange({}).$promise.then(validRange => {
                this.minDate = new Date(validRange.min);
                this.maxDate = new Date(validRange.max);
            });

            this.productGlobalStats = {
                titleLabelCode: 'statistics.product.stats.global',
                displayTextFilter: false,
                initiallyExpanded: true,
                secondaryMatchAttribute: 'customer',
                enablePaging: false,
                initialQuery: {withBrand: false, withSupplier:false},
                columns: [
                    {field: 'setupCount', labelCode: 'statistics.product.stats.setup'},
                    {field: 'productionCount', labelCode: 'statistics.product.stats.production'},
                    {field: 'notProductionCount', labelCode: 'statistics.product.stats.not.production'},
                    {field: 'rejectedCount', labelCode: 'statistics.product.stats.rejected'}
                ],
                resource: this.ProductStatusStatResource
            };

            this.productBrandStats = {
                titleLabelCode: 'statistics.product.stats.by.brand',
                displayTextFilter: true,
                initiallyExpanded: false,
                secondaryMatchAttribute: 'brand',
                enablePaging: true,
                initialQuery: {withBrand: true, withSupplier:false},
                columns: [
                    {field: 'brand.name', labelCode: 'brand', hideSecondary: true},
                    {field: 'setupCount', labelCode: 'statistics.product.stats.setup'},
                    {field: 'productionCount', labelCode: 'statistics.product.stats.production'},
                    {field: 'notProductionCount', labelCode: 'statistics.product.stats.not.production'},
                    {field: 'rejectedCount', labelCode: 'statistics.product.stats.rejected'}
                ],
                prepareSecondaryRequestFilter: (secondaryQuery, primaryPage) => {
                    secondaryQuery.brandIds = primaryPage.content.map(entity => entity.brand.id);
                },
                resource: this.ProductStatusStatResource
            };

            this.productSupplierStats = {
                titleLabelCode: 'statistics.product.stats.by.supplier',
                displayTextFilter: true,
                initiallyExpanded: false,
                secondaryMatchAttribute: 'supplier',
                enablePaging: true,
                initialQuery: {withBrand: false, withSupplier: true},
                columns: [
                    {field: 'supplier.name', labelCode: 'supplier', hideSecondary: true},
                    {field: 'setupCount', labelCode: 'statistics.product.stats.setup'},
                    {field: 'productionCount', labelCode: 'statistics.product.stats.production'},
                    {field: 'notProductionCount', labelCode: 'statistics.product.stats.not.production'},
                    {field: 'rejectedCount', labelCode: 'statistics.product.stats.rejected'}
                ],
                prepareSecondaryRequestFilter: (secondaryQuery, primaryPage) => {
                    secondaryQuery.supplierIds = primaryPage.content.map(entity => entity.supplier.id);
                },
                resource: this.ProductStatusStatResource
            };

            this.jobCompletionStat = {
                titleLabelCode: 'statistics.by.job',
                displayTextFilter: true,
                initiallyExpanded: true,
                secondaryMatchAttribute: 'job',
                enablePaging: true,
                columns: [
                    {field: 'job.name', labelCode: 'job', hideSecondary: true},
                    {field: 'productsRequestedCount', labelCode: 'statistics.job.stats.product.requests'},
                    {field: 'productsShotCount', labelCode: 'statistics.job.stats.product.done'},
                    {field: 'progressionPercent', labelCode: 'statistics.job.stats.product.completion', sortable: false}

                ],
                entityPostProcess: entity => {
                    if (!entity.productsRequestedCount) {
                        entity.productsToShootCount = '0 %';
                        entity.productsShotCount = 0;
                        return;
                    }
                    entity.productsShotCount = entity.productsRequestedCount - entity.productsToShootCount;
                    let progression = entity.productsShotCount / entity.productsRequestedCount;
                    entity.progressionPercent = Math.round(progression * 100) + ' %';
                },
                prepareSecondaryRequestFilter: (secondaryQuery, primaryPage) => {
                    secondaryQuery.jobIds = primaryPage.content.map(entity => entity.job.id);
                },
                resource: this.JobCompletionStatResource
            };

            this.keychainSharingStat = {
                titleLabelCode: 'statistics.by.customer',
                displayTextFilter: true,
                initiallyExpanded: true,
                secondaryMatchAttribute: 'customer',
                enablePaging: true,
                columns: [
                    {field: 'customer.name', labelCode: 'customer', hideSecondary: true},
                    {field: 'sharedProductNumber', labelCode: 'statistics.keychain.stats.shared'},
                    {field: 'keychainNames', labelCode: 'accesses.keychains'}

                ],
                prepareSecondaryRequestFilter: (secondaryQuery, primaryPage) => {
                    secondaryQuery.customerIds = primaryPage.content.map(entity => entity.customer.id);
                },
                resource: this.KeychainSharingStatResource
            };

            this.userValidationStat = {
                titleLabelCode: 'statistics.by.user',
                displayTextFilter: this.isInternalUser,
                initiallyExpanded: true,
                secondaryMatchAttribute: 'customer',
                enablePaging: this.isInternalUser,
                initialQuery: {withUser: true},
                columns: [
                    {field: 'customer.name', labelCode: 'customer', hideSecondary: true},
                    {field: 'user.login', labelCode: 'user', hideSecondary: true},
                    {field: 'productSetupCount', labelCode: 'statistics.product.stats.setup'},
                    {field: 'productRejectedCount', labelCode: 'statistics.product.stats.rejected'},
                    {field: 'rejectionTotal', labelCode: 'statistics.validation.stats.rejected.number'}
                ],
                prepareSecondaryRequestFilter: (secondaryQuery, primaryPage) => {
                    secondaryQuery.userIds = primaryPage.content.map(entity => entity.user.id);
                },
                resource: this.UserValidationStatResource
            };
        });

    }
}

mainModule.component('customerStatsProduct', {
    controller: Controller,
    template: template.default
});
