import {IComponentOptions, IController} from 'angular';
import {UserContextService} from "../user/user-context-service";
import {ScenarioResource} from "../api/legacy/api/ScenarioResource";
import * as template from "./product-scenario-list.component.html";
import {mainModule} from "../app";
import {UserRole} from "vtom-api-typescript-client";
import * as productScenarioModal from "./product-scenario-modal.html";
import * as scenarioAccessesBulkConfigurationTemplate from "./scenario-accesses-bulk-configuration.html";


class Controller implements IController {
    // Input
    product;
    // Output
    onChange: ({scenarios: []}) => void;

    isAdmin: boolean;
    scenarios: any[];

    constructor(
        private readonly UserContextService: UserContextService,
        private readonly ScenarioResource: ScenarioResource,
        private readonly DialogService
    ) {}


    fetchScenarioPaths(): void {
        this.scenarios.forEach(scenario => this.fetchScenarioPath(scenario));
    }

    fetchScenarioPath(scenario): void {
        if (!this.isAdmin || scenario.path) {
            return;
        }
        this.ScenarioResource.getResourcesPath({scenario: {id: scenario.id}}).$promise.then(path => {
            const pathArray = path.replace(/\/*$/, '').split('/');
            pathArray.splice(0, pathArray.length - 3);
            scenario.path = '/' + pathArray.join('/');
        });
    };

    addScenario(): void {
        this.DialogService.openModal({
            template: productScenarioModal.default,
            controller: 'ProductScenarioModalCtrl',
            resolve: {
                product: () => this.product
            }
        }).result.then(scenario => {
            this.scenarios.push(scenario);
            this.fetchScenarioPaths();
            this.notifyChangeListener();
        });
    };

    getSelectedScenarios(): any[] {
        if (!this.scenarios) {
            return [];
        }
        return this.scenarios.filter(scenario => scenario.selected == true);
    };


    configureAccessesKeyChains(): void {
        this.DialogService.openModal({
            template: scenarioAccessesBulkConfigurationTemplate.default,
            controller: 'ScenarioAccessesBulkConfigurationCtrl',
            resolve: {
                scenarios: () => this.getSelectedScenarios()
            }
        });
    };

    isDisplayed(): boolean {
        return this.product
            && this.product.id;
    }

    notifyChangeListener(): void {
        if (this.onChange) {
            this.onChange({
                scenarios: this.scenarios
            });
        }
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.product?.id) {
            return;
        }
        if (onChangesObj.product.previousValue
            && onChangesObj.product.previousValue.id == this.product.id) {
            return;
        }
        this.UserContextService.getContext().then(userContext => {
            this.isAdmin = userContext.hasRole(UserRole.ADMIN);

            if (!this.isDisplayed()) {
                return;
            }

            this.ScenarioResource.findAll({
                filter: {
                    productIds: [this.product.id],
                    entityGraphName: 'scenario.with.customer.and.product'
                }
            }).$promise.then(scenarios => {
                this.scenarios = scenarios;
                this.fetchScenarioPaths();
                this.notifyChangeListener();
            });
        });
    }

}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        product: '<',
        onChange: '&'
    }
};

mainModule.component('productScenarioList', component);
