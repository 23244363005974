import * as angular from 'angular';
import {ScenariosApi, ProductsApi, Scenario, Product} from "vtom-api-typescript-client";
import {IComponentOptions, IController, ILocationService, IPromise, IQService, IScope, IWindowService} from "angular";
import * as template from "./scenario.component.html";
import {mainModule} from "../../app";
import {UserContextService} from "../../user/user-context-service";
import {ScenarioValidationResource} from "../../api/legacy/api/ScenarioValidationResource";
import {ScenarioStatusHistoryResource} from "../../api/legacy/api/ScenarioStatusHistoryResource";
import {ScenarioAccessResource} from "../../api/legacy/api/ScenarioAccessResource";
import {ScenarioTypeUtils} from "./scenario-type-utils";

class Controller implements IController {
    id: number;
    scenario: Scenario;
    product: Product;
    productValidation;
    statusHistory;

    isAdmin: boolean;
    isShooter: boolean;

    toScenarioTypeLabelCode = ScenarioTypeUtils.toLabelCode;

    constructor(
        private readonly $scope: IScope,
        private readonly $translate,
        private readonly $window: IWindowService,
        private readonly $location: ILocationService,
        private readonly UserContextService: UserContextService,
        private readonly NavbarService,
        private readonly DialogService,
        private readonly ScenarioValidationResource: ScenarioValidationResource,
        private readonly ScenarioStatusHistoryResource: ScenarioStatusHistoryResource,
        private readonly ScenarioAccessResource: ScenarioAccessResource,
        private readonly ProductsApi: ProductsApi,
        private readonly ScenariosApi: ScenariosApi
    ) {}

    $onInit() {
        this.NavbarService.setAppTitleFromObjectName('scenario', this.$scope, 'scenario.product.model');
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.id || onChangesObj.id.previousValue == onChangesObj.id.currentValue) {
            return;
        }
        this.ScenariosApi.retrieveScenario({scenarioId: this.id})
            .then(scenario => {
                this.scenario = scenario;
                return this.ProductsApi.retrieveProduct({productId: scenario.productId});
            })
            .then(product => this.product = product);

        this.UserContextService.getContext().then(userContext => {
            this.isAdmin = userContext.hasRole('ADMIN');
            this.isShooter = this.isAdmin || userContext.hasRole('SHOOTER');

            if (!this.isAdmin && !this.isShooter) {
                return;
            }
            this.productValidation = this.ScenarioValidationResource.findLastByScenario({scenario: {id: this.id}});
            this.statusHistory = this.ScenarioStatusHistoryResource.findByScenario({scenario: {id: this.id}});
        });
    }

    delete() {
            this.DialogService.confirm(this.$translate('general.confirmDeletion')).result
                .then(() => this.ScenariosApi.deleteScenario({scenarioId: this.id}))
                .then(() => this.ProductsApi.retrieveProduct({productId: this.scenario.productId}))
                .then(() => this.$window.history.back())
                .catch(() => this.$location.path('/product/try-on-available'));
    }
}

const component: IComponentOptions = {
    bindings: {
        id: '<'
    },
    controller: Controller,
    template: template.default
};

mainModule.component('scenario', component);
