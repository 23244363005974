import * as angular from 'angular';
import {IComponentOptions, IController} from 'angular';
import * as template from './customer-product-shooting-modes.html';
import {EnumService} from "../misc/enum-service";
import {ProductResource} from "../api/legacy/api/ProductResource";
import {CustomerProductShootingModeResource} from "../api/legacy/api/CustomerProductShootingModeResource";
import {mainModule} from "../app";

class Controller implements IController {

    private customer;
    private shootingModes: Array<any>;
    private customerShootingModes: Array<any>;

    constructor(
        private readonly $routeParams,
        private readonly ProductResource: ProductResource,
        private readonly EnumService: EnumService,
        private readonly CustomerProductShootingModeResource: CustomerProductShootingModeResource) {
        this.customer = {id: $routeParams.id};
        this.shootingModes = ProductResource.getAllShootingModes({});
    }

    private hasShootingMode(shootingMode) {
        return this.EnumService.hasTranslatableEnum(shootingMode, this.customerShootingModes);
    };

    private toggleShootingMode(shootingMode) {
        this.EnumService.toggleTranslatableEnum(shootingMode, this.customerShootingModes);
    };

    private save() {
        this.CustomerProductShootingModeResource.saveForCustomer({customer: this.customer, shootingModes: this.customerShootingModes});
    };



    public $onInit(): void {
        this.customerShootingModes = this.CustomerProductShootingModeResource.findByCustomer({customer: this.customer});
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('customerProductShootingMode', component);
