import './app'
import './Routing';
import './api/api-factory.ts';
import './api/api-mapper-service.ts';
import './api/api-pageable-helper.ts';
import './api/api-pageable-iterator.ts';
import './api/legacy/api.module.ts';
import './api/legacy/api/AccessesKeyChainResource.ts';
import './api/legacy/api/api.ts';
import './api/legacy/api/ApplicationResource.ts';
import './api/legacy/api/BoothResource.ts';
import './api/legacy/api/BoothSettingExcludedProductResource.ts';
import './api/legacy/api/BoothSettingProductResource.ts';
import './api/legacy/api/BoothSettingResource.ts';
import './api/legacy/api/BrandResource.ts';
import './api/legacy/api/CustomerBillingResource.ts';
import './api/legacy/api/CustomerGroupResource.ts';
import './api/legacy/api/CustomerProductImportModeResource.ts';
import './api/legacy/api/CustomerProductShootingModeResource.ts';
import './api/legacy/api/CustomerResource.ts';
import './api/legacy/api/DefaultResource.ts';
import './api/legacy/api/ExportScenarioResource.ts';
import './api/legacy/api/JobCompletionStatResource.ts';
import './api/legacy/api/JobDefaultShootingModeResource.ts';
import './api/legacy/api/JobFileResource.ts';
import './api/legacy/api/JobResource.ts';
import './api/legacy/api/JobValidatorResource.ts';
import './api/legacy/api/KeychainSharingStatResource.ts';
import './api/legacy/api/ProcessesResource.ts';
import './api/legacy/api/ProcessHistoryResource.ts';
import './api/legacy/api/ProcessHistoryUnitResource.ts';
import './api/legacy/api/ProductColorDominanceResource.ts';
import './api/legacy/api/ProductColorLabDeltaResource.ts';
import './api/legacy/api/ProductFrameShapeResource.ts';
import './api/legacy/api/ProductResource.ts';
import './api/legacy/api/ProductShootingCommentResource.ts';
import './api/legacy/api/ProductStatusStatResource.ts';
import './api/legacy/api/RegisteredDomainResource.ts';
import './api/legacy/api/ScenarioAccessResource.ts';
import './api/legacy/api/ScenarioResource.ts';
import './api/legacy/api/ScenarioStatusHistoryResource.ts';
import './api/legacy/api/ScenarioValidationResource.ts';
import './api/legacy/api/SunGlassResource.ts';
import './api/legacy/api/SupplierResource.ts';
import './api/legacy/api/TryOnBrowserStatResource.ts';
import './api/legacy/api/TryOnDeviceStatResource.ts';
import './api/legacy/api/TryOnLibVersionStatResource.ts';
import './api/legacy/api/TryOnSessionStatResource.ts';
import './api/legacy/api/UniversalProductResource.ts';
import './api/legacy/api/UserPasswordResource.ts';
import './api/legacy/api/UserResource.ts';
import './api/legacy/api/UserRoleResource.ts';
import './api/legacy/api/UserValidationStatResource.ts';
import './api/legacy/container.ts';
import './api/legacy/index.ts';
import './api/legacy/model/models.ts';
import './api/legacy/resource.ts';
import './app.ts';
import './app/app-ctrl.ts';
import './app/vtom-translation-loader.ts';
import './app/welcome-ctrl.ts';
import './brand/brand-ctrl.ts';
import './brand/brand-list-ctrl.ts';
import './conf/application-context-service.ts';
import './customer/customer-billing.component.ts';
import './customer/customer-ctrl.ts';
import './customer/customer-group-ctrl.ts';
import './customer/customer-group-list-ctrl.ts';
import './customer/customer-layout-directive.ts';
import './customer/customer-list-ctrl.ts';
import './customer/customer-product-import-modes.component.ts';
import './customer/customer-product-shooting-modes.component.ts';
import './customer/customer-select-modal-ctrl.ts';
import './customer/registered-domain-ctrl.ts';
import './customer/registered-domain-list.component.ts';
import './customer/registered-domain-type.ts';
import './enduser/shot-ctrl.ts';
import './export_scenario/export-scenario-ctrl.ts';
import './export_scenario/export-scenario-list-ctrl.ts';
import './fileupload/file-upload-directive.ts';
import './fileupload/managed-file-upload-control.ts';
import './framework/authentication/authentication-config.ts';
import './framework/authentication/authentication-manager.ts';
import './framework/cache/cache-evicter.ts';
import './framework/dialogs/alert-dialog-ctrl.ts';
import './framework/dialogs/confirm-dialog-ctrl.ts';
import './framework/dialogs/countdown-dialog-ctrl.ts';
import './framework/dialogs/dialog-service.ts';
import './framework/dialogs/error-dialog-ctrl.ts';
import './framework/dialogs/modal-size.ts';
import './framework/dialogs/wait-dialog-ctrl.ts';
import './framework/filter/boolean-translate-filter.ts';
import './framework/filter/enum-to-label-code-filter.ts';
import './framework/filter/enum-to-string-filter.ts';
import './framework/filter/escape-html-filter.ts';
import './framework/form/cos-match-directive.ts';
import './framework/form/cos-select-directive.ts';
import './framework/form/ng-model-file-input-directive.ts';
import './framework/grid/cos-grid-directive.ts';
import './framework/grid/cos-grid-service.ts';
import './framework/grid/csv/csv-column.ts';
import './framework/grid/csv/csv-grid-configuration.ts';
import './framework/grid/csv/csv-grid-options.ts';
import './framework/grid/grid-configuration-resource.ts';
import './framework/http/http-config.ts';
import './framework/http/http-error-handler.ts';
import './framework/http/http-interceptor.ts';
import './framework/http/resource-configurator.ts';
import './framework/http/transform-response-as-file.ts';
import './framework/i18n/angular-i18n-loader.run.ts';
import './framework/i18n/composite-translation-files-loader.provider.ts';
import './framework/i18n/framework-translation-files-loader.service.ts';
import './framework/i18n/interpolable-message-service.ts';
import './framework/i18n/map-translate-filter.ts';
import './framework/i18n/missing-translation-handler.ts';
import './framework/i18n/simple-translation-service.ts';
import './framework/i18n/translation-configurator.ts';
import './framework/i18n/translation-service.ts';
import './framework/location/location-change-service.ts';
import './framework/location/location-provider-configurator.ts';
import './framework/log/log.configurator.ts';
import './framework/notification/notification.options.ts';
import './framework/notification/save-notification.configurator.ts';
import './framework/notification/toastr-configurator.ts';
import './framework/util/eager-loader.runner.ts';
import './image_processing/image-effect-types.ts';
import './image_processing/image-output-formats.ts';
import './image_processing/image-style.component.ts';
import './image_processing/transparent-trim-directive.ts';
import './index.ts';
import './job/job-ctrl.ts';
import './job/job-layout-directive.ts';
import './job/job-list-ctrl.ts';
import './job/job-scenario-list.component.ts';
import './job/validator/job-validator-ctrl.ts';
import './job/validator/job-validator-list-ctrl.ts';
import './keychain/accesses-key-chain-ctrl.ts';
import './keychain/accesses-key-chain-layout-directive.ts';
import './keychain/accesses-key-chain-list-ctrl.ts';
import './keychain/accesses-key-chains-resource.ts';
import './keychain/add-all-products-to-accesses-key-chain.ts';
import './layout/cos-grid-server-pageable.ts';
import './layout/cos-grid-sort.ts';
import './layout/filter.function.ts';
import './layout/grid-action.ts';
import './layout/interpolable-message-filter.ts';
import './layout/process-multi-result-ctrl.ts';
import './layout/remote-paged-grid-layout-directive.ts';
import './layout/simple-list-layout-directive.ts';
import './misc/enum-service.ts';
import './navigation/navbar-service.ts';
import './navigation/primary-navbar.component.ts';
import './navigation/secondary-navbar.component.ts';
import './navigation/url-context-service.ts';
import './processes/history/processes-history-ctrl.ts';
import './processes/history/processes-history-list-ctrl.ts';
import './processes/history/processes-history-unit-ctrl.ts';
import './processes/processes-ctrl.ts';
import './product/import/product-import-ctrl.ts';
import './product/import/product-import-resource.ts';
import './product/product-addition-add-modal-ctrl.ts';
import './product/product-addition-compatibility.component.ts';
import './product/product-enum-utils.ts';
import './product/product-frame-shapes.component.ts';
import './product/product-list-ctrl.ts';
import './product/product-scenario-list.component.ts';
import './product/product-scenario-modal-ctrl.ts';
import './product/product-shooting-comment.component.ts';
import './product/product.component.ts';
import './product/scenario-accesses-bulk-configuration-ctrl.ts';
import './product/scenario/scenario-file-type-utils.ts';
import './product/scenario/scenario-filter.ts';
import './product/scenario/scenario-keychain-edit.component.ts';
import './product/scenario/scenario-type-utils.ts';
import './product/scenario/scenario.component.ts';
import './Routing.ts';
import './shooting/artifact-platform.ts';
import './shooting/shooting-release-edit.component.ts';
import './shooting/shooting-release-list.component.ts';
import './shooting/shooting-release-view.component.ts';
import './shooting/software-release.service.ts';
import './stats/customer-stats-block.component.ts';
import './stats/customer-stats-products.component.ts';
import './stats/customer-stats-top-filter.component.ts';
import './stats/customer-stats-vto.component.ts';
import './styles/css-service.ts';
import './supplier/supplier-ctrl.ts';
import './supplier/supplier-list-ctrl.ts';
import './tryon_booth/booth-ctrl.ts';
import './tryon_booth/booth-list-ctrl.ts';
import './tryon_booth/booth-setting-base-ctrl.ts';
import './tryon_booth/booth-setting-booth-ctrl.ts';
import './tryon_booth/booth-setting-layout-directive.ts';
import './tryon_booth/booth-setting-list-ctrl.ts';
import './tryon_booth/booth-setting-product-ctrl.ts';
import './tryon/try-on-360.component.ts';
import './tryon/try-on-api-service.ts';
import './tryon/try-on-booth.component.ts';
import './tryon/try-on-gallery.component.ts';
import './tryon/try-on-picture.component.ts';
import './tryon/try-on-video.component.ts';
import './tryon/visagesdk/visage-license-domain.component.ts';
import './tryon/visagesdk/visage-licenses-list.component.ts';
import './typings.d.ts';
import './user/login-ctrl.ts';
import './user/user-context-service.ts';
import './user/user-ctrl.ts';
import './user/user-list-ctrl.ts';
import './user/user-service.ts';
import './validation/job-validation-list.component.ts';
import './validation/job-validation.component.ts';
import './validation/scenario-rejection-dialog-ctrl.ts';
import './vto_editor/vto-editor-ctrl.ts';
